.dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  flex: 0 0 auto;
  @media (min-width: 1023px) {
    display: none;
  }

  .dropdownMenu {
    z-index: 5;
    transform: translate3d(0, 2.75rem, 0) !important;
    width: 100%;
  }
}

.desktopDropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    display: none;
  }

  .dropdownMenu {
    z-index: 5;
    transform: translate3d(0, 4.75rem, 0) !important;
    width: 100%;
  }
}
.topContent {
  background-color: rgba(245, 247, 248, 1);
  width: 105%;
  align-self: center;
}
.message {
  display: none!important;
}
.mobileMessage {
  display: flex!important;
  height: 2.5rem;
  p {
    text-align: center!important;
    align-self: center!important;
    font-size: .625rem!important;
  }
}

.middleContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 3.75rem;
  padding: 0rem 2% 0rem 2%;
  > * {
    margin-left: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.4rem 0rem 1rem 0rem;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .logo {
    overflow: hidden;
    align-self: flex-start;
    width: 12.25rem;
    padding: 0.8rem 1rem 1rem 0rem;
    flex: 0 0 1.25rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .widgetContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.85rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 6.625rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .widget {
    padding: 0rem 0rem 0rem 0rem;
    overflow: hidden;
    align-self: flex-start;
    width: 6.125rem;
    flex: 0 0 2.29375rem;
  }
}
.bottomContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120%;
  align-self: center;
  padding: 0 14% 0 15%;
  background: #00262F;
  height: 2.5rem;
}
.bottomRight {
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.phoneNumber {
  height: 1rem;
  align-self: center!important;
}
.label {
  height: 1rem;
  align-self: center!important;
}
.phoneIcon {
  height: 1rem;
  align-self: center!important;
}

@media (min-width: 1024px) {
  .topContent {
    background-color: rgba(245, 247, 248, 1);
    width: 110%;
  }
  .message {
    display: flex!important;
    height: 2.5rem;
    p {
      text-align: center!important;
      align-self: center!important;
    }
  }
  .mobileMessage {
    display: none!important;
  }
  .middleContent {
    width: 110%;
    padding: 0 6rem 0 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > * {
      margin-left: 1rem;
    }
    align-self: center;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .logoContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.15rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 11.875rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }

    .logo {
      padding: 0rem 0rem 0rem 0rem;
      overflow: hidden;
      align-self: flex-start;
      width: 11.875rem;
      flex: 0 0 1.9375rem;
    }

    .widgetContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.525rem 3rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 7.875rem;
      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }

    .widget {
      padding: 0rem 0rem 0rem 0rem;
      overflow: hidden;
      align-self: flex-start;
      width: 7.6rem;
      flex: 0 0 2.29375rem;
    }
  }
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 110%;
    padding: 0 9rem 0 7rem;
    background: #00262F;
    height: 2.5rem;
  }
  .bottomRight {
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .phoneNumber {
    height: 1rem;
    align-self: center!important;
  }
  .label {
    height: 1rem;
    align-self: center!important;
    p {
      font-weight: 600!important;
    }
  }
  .phoneIcon {
    height: 1rem;
    align-self: center!important;
  }
}

@media (min-width: 1024px) {
  .dropdown {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    @media (min-width: 1023px) {
      display: none;
    }

    .dropdownMenu {
      z-index: 5;
      transform: translate3d(0, 4.75rem, 0) !important;
      width: 100%;
    }
  }
}
