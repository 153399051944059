.hideIcons {
  display: none;
}

.receiptPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .receiptBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1rem 1.5rem 1rem;
    > * {
      margin-top: 1.5rem;
    }
    align-self: flex-start;
    flex: 0 0 52.1875rem;
    @media (min-width: 1024px) {
      width: 60rem;
    }
    .totalCardPaymentFont p {
      font-size: 0.875rem;
    }
  }
}
