.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .divider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2.5rem 1rem 2.5rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .logo {
    align-self: center;
    height: 1.5625rem;
    flex: 0 0 4.625rem;
  }

  .footerMenuList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    > * {
      margin-top: 1rem;
      margin-left: 1rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

}

@media (min-width: 1024px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);
  
    .divider {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2.5rem 1rem 2.5rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .logo {
      align-self: center;
      height: 3.125rem;
      flex: 0 0 8.9375rem;
    }
  
    .footerMenuList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      > * {
        margin-top: 1.5rem;
        margin-left: 1.5rem;
      }
      align-self: center;
      flex: 1 1 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);
  
    .divider {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2.5rem 1rem 2.5rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .logo {
      align-self: center;
      height: 3.125rem;
      flex: 0 0 8.9375rem;
    }
  
    .footerMenuList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      > * {
        margin-top: 1.5rem;
        margin-left: 1.5rem;
      }
      align-self: center;
      flex: 1 1 auto;
    }
  
  }
}