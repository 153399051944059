.linkPath {
  text-decoration: none;
}

.buttonTextContainedLarge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(0, 102, 153, 1);

  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedLarge:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 118, 177);
  outline: 2px dashed rgb(2, 118, 177);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedLarge:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedLarge:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(1, 78, 116);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenLarge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(37, 129, 14);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenLarge:disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(37, 129, 14);
  opacity: 0.3;
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenLarge:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(37, 129, 14);
  outline: 2px dashed rgb(37, 129, 14);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenLarge:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(30, 106, 11);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenLarge:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(24, 83, 9);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedLarge:disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 118, 177);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedMedium:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 118, 177);
  outline: 2px dashed rgb(2, 118, 177);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 101, 151);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(1, 78, 116);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(37, 129, 14);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenMedium:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(37, 129, 14);
  outline: 2px dashed rgb(37, 129, 14);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(30, 106, 11);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedGreenMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(24, 83, 9);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextContainedMedium:disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlineMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px solid rgb(2, 118, 177);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlineMedium:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(2, 118, 177);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlineMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 101, 151);
  outline: 2px solid rgb(2, 101, 151);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      color: rgb(255, 255, 255);
    }
  }

}
.buttonTextOutlineMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(1, 78, 116);
  outline: 2px solid rgb(1, 78, 116);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      color: rgb(255, 255, 255);
    }
  }

}
.buttonTextOutlineMedium:disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlinePillMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px solid rgb(2, 118, 177);
  border-radius: 100px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlinePillMedium:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(2, 118, 177);
  border-radius: 100px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlinePillMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 101, 151);
  outline: 2px solid rgb(2, 101, 151);
  border-radius: 100px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlinePillMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(1, 78, 116);
  outline: 2px solid rgb(1, 78, 116);
  border-radius: 100px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlinePillMedium:disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border-radius: 100px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlineSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 1rem 0.125rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px solid rgb(255, 255, 255);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlineSmall:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 1rem 0.125rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(255, 255, 255);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlineSmall:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 1rem 0.125rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  outline: 2px solid rgb(255, 255, 255);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlineSmall:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 1rem 0.125rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(229, 229, 229);
  outline: 2px solid rgb(228, 229, 229);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextOutlineSmall:disabled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 1rem 0.125rem 1rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextTextGreySmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextSmall:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(2, 118, 177);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextSmall:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextSmall:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextMedium:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(2, 118, 177);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextWhiteSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 0rem 0.125rem 0rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: none;
    }
  }

}
.buttonTextTextWhiteSmall:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 0rem 0.125rem 0rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(255, 255, 255);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: none;
    }
  }

}
.buttonTextTextWhiteSmall:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 0rem 0.125rem 0rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: none;
    }
  }

}
.buttonTextTextWhiteSmall:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 0rem 0.125rem 0rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextWhiteMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextWhiteMedium:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(255, 255, 255);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextWhiteMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextWhiteMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextDarkMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextDarkMedium:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(61, 61, 61);
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextDarkMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextTextDarkMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 2px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;

    p{
      text-decoration: underline;
    }
  }

}
.buttonTextIconTextSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    overflow: hidden;
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonTextIconTextUnderlineSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    overflow: hidden;
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonTextIconTextSmall:focus-visible {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(2, 101, 151);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    overflow: hidden;
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonTextIconTextSmall:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    overflow: hidden;
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonTextIconTextSmall:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonTextIconTextMedium {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonTextIconTextMedium:focus-visible {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(61, 61, 61);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonTextIconTextMedium:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextTextSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextTextSmall:focus-visible {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(2, 118, 177);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextTextSmall:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextTextExtraSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.0625rem 0rem 0.125rem 0rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextTextExtraSmall:focus-visible {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(2, 118, 177);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.0625rem 0rem 0.125rem 0rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextTextExtraSmall:active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

  .icon {
    overflow: hidden;
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 100px;

  .icon {
    overflow: hidden;
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconTextSmall:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  outline: 2px dashed rgb(255, 255, 255);
  border-radius: 100px;

  .icon {
    overflow: hidden;
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconTextSmall:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(255, 255, 255, 0.4000000059604645);
  border-radius: 100px;

  .icon {
    overflow: hidden;
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconTextSmall:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(255, 255, 255, 0.5600000023841858);
  border-radius: 100px;

  .icon {
    overflow: hidden;
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonTextContainedWhiteMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(255, 255, 255, 0.32);

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextContainedWhiteMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(255, 255, 255, 0.40);

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextContainedWhiteMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(255, 255, 255, 0.56);

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonIconTextMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 2rem;
  flex: 0 0 2rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(255, 255, 255, 0.31);

  .icon {
    overflow: hidden;
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconTextMedium:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 2rem;
  flex: 0 0 2rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(255, 255, 255, 0.40);

  .icon {
    overflow: hidden;
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconTextMedium:active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 2rem;
  flex: 0 0 2rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgba(255, 255, 255, 0.56);

  .icon {
    overflow: hidden;
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
  }

}