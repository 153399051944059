.sliderInteraction {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-top: 1.5rem;
  }

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

  .miles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

  .mobileSlider {
    display: flex;
    align-self: stretch;
    flex: 1 1 auto;
    margin-top: 0.625rem;
    margin-bottom: 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
  .slider {
    display: none;
  }

  .manualInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-top: 0.25rem;
    }
    align-self: center;
    flex: 0 0 auto;
    margin-top: 0;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .inputLine {
    display: flex;
    flex-direction: row;

    span {
      padding: 0.4rem;
    }
  }

  .pointsInput {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.15rem 0.5rem 0.15rem 0.5rem;
    > * {
      align-self: center;
      flex: 1 1 2rem;
    }
    align-self: center;
    min-width: 5.5rem;
    flex: 1 1 1.5rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .pointsInput:active {
    border: 1px solid black;
    @media (min-width: 1023px) {
      margin-top: 0.25rem;
    }
  }

  .convertedValue {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    width: 20rem;
    flex: 0 0 2.625rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .dollarValue {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .remainingBalance {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

.liveRegionArea {
  position: absolute;
  left: -999px;
  color: white;
}

@media (min-width: 1024px) {
  .sliderInteraction {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-top: 1.5rem;
    }

    .topContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      > * {
        margin-left: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .miles {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      flex: 0 0 auto;
    }

    .text {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      flex: 0 0 auto;
    }

    .slider {
      display: flex;
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .mobileSlider {
      display: none;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: flex-start;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .manualInput {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 0;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .pointsInput {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.15rem 0.5rem 0.15rem 0.5rem;
      > * {
        align-self: center;
        flex: 1 1 2rem;
      }
      align-self: center;
      min-width: 5.5rem;
      flex: 1 1 1.5rem;
    }

    .convertedValue {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      width: 20rem;
      flex: 0 0 2.625rem;
    }

    .dollarValue {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      flex: 0 0 auto;
    }

    .remainingBalance {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 1.5rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}
