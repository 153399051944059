.textHeadingSmallSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(17, 125, 180);
  }
}

.textHeadingSmallLightBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
    color: rgb(20, 20, 20);
  }
}
.textHeadingSmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textSubheadingLargeLightSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textSubheadingLargeLightSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textSubheadingLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textSubheadingLargeSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 2.25rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textSubheadingLargeSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 2.25rem;
    text-align: left;
    color: rgb(2, 118, 177);
  }
}
.textSubheadingLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textSubheadingLargeLightSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: center;
    color: rgb(105, 105, 105);
  }
}
.textSubheadingLargeRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
    color: rgb(105, 105, 105);
  }
}
.textSubheadingMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textSubheadingMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(17, 125, 180);
  }
}
.textSubheadingMediumSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(17, 125, 180);
  }
}
.textSubheadingMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(2, 101, 151);
  }
}
.textSubheadingMediumRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(1, 78, 116);
  }
}
.textSubheadingMediumLightSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textSubheadingMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textSubheadingMediumRegularSubduedDarkRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: right;
    color: rgb(61, 61, 61);
  }
}
.textSubheadingMediumLightSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(105, 105, 105);
  }
}
.textSubheadingMediumLightSubduedLightRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: right;
    color: rgb(105, 105, 105);
  }
}
.textSubheadingMediumRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(125, 125, 125);
  }
}
.textDecorationLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: rgb(61, 61, 61);
    color: rgb(61, 61, 61);
  }
}
.textDecorationLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBodyLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBodyLargeRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(20, 20, 20);
  }
}
.textBodyLargeRegularBaseDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(20, 20, 20);
  }
}
.textBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textBodyExtraLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textBodyLargeRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(105, 105, 105);
  }
}
.textBodyLargeRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(125, 125, 125);
  }
}
.textBodyLargeRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(125, 125, 125);
  }
}
.textBodyLargeSemiBoldBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(20, 20, 20);
  }
}
.textBodyLargeSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textBodyLargeSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBodyLargeSemiBoldSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(105, 105, 105);
  }
}
.textBodyLargeSemiBoldDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(125, 125, 125);
  }
}
.textBodyLargeRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(2, 118, 177);
  }
}
.textBodyLargeSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(2, 118, 177);
  }
}
.textBodyLargeRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(2, 101, 151);
  }
}
.textBodyLargeRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(1, 78, 116);
  }
}
.textDecorationMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textDecorationMediumSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textDecorationMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: rgb(2, 101, 151);
    color: rgb(2, 101, 151);
  }
}
.textDecorationMediumRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(1, 78, 116);
  }
}
.textBodyMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBodyMediumRegularActionLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(37, 95, 130);
  }
}
.textDecorationSmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textDecorationSmallRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(1, 78, 116);
  }
}
.textBodyMediumRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(20, 20, 20);
  }
}
.textBodyMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(2, 118, 177);
  }
}
.textBodyMediumRegularBaseDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
    color: rgb(20, 20, 20);
  }
}
.textBodyMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textBodySmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textBodyMediumRegularSubduedDarkRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: right;
    color: rgb(61, 61, 61);
  }
}
.textBodyMediumRegularBrandLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(16, 37, 63);
  }
}
.textBodyMediumSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textBodyMediumSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.3125rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textBodyMediumSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.3125rem;
    text-align: center;
    color: rgb(125, 125, 125);
  }
}
.textBodyMediumRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(105, 105, 105);
  }
}
.textBodyMediumRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(125, 125, 125);
  }
}
.textBodyMediumRegularPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(30, 106, 11);
  }
}
.textBodySmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBodySmallSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBodySmallRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(105, 105, 105);
  }
}
.textBodySmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textBodySmallRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
    color: rgb(125, 125, 125);
  }
}
.textBodySmallRegularNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(204, 36, 39);
  }
}
.textBodySmallSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textBodySmallSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.125rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textBodySmallSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.125rem;
    text-align: center;
    color: rgb(125, 125, 125);
  }
}
.textBodySmallRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(2, 118, 177);
  }
}
.textBodySmallSemiBoldActionLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(2, 118, 177);
  }
}
.textBodySmallSemiBoldPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(30, 106, 11);
  }
}

.textBodySmallSemiBoldWarningLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(20, 20, 20);
  }
}

.textBodySmallSemiBoldNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(204, 36, 39);
  }
}
.textBodyExtraSmallSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.625rem;
    font-weight: normal;
    line-height: 0.75rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textBodyExtraSmallSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.625rem;
    font-weight: normal;
    line-height: 0.75rem;
    text-align: center;
    color: rgb(125, 125, 125);
  }
}
.textBodyExtraExtraSmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textBodyExtraExtraSmallRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
    color: rgb(125, 125, 125);
  }
}
.textHeadingSmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }
}
.textSubheadingSmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textSubheadingSmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }
}
.textBodyLargeRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }
}
.textBodyLargeLightSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textBodyLargeRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(61, 61, 61);
  }
}
.textBodyLargeRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(105, 105, 105);
  }
}
.textBodyLargeRegularActionBaseCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(2, 118, 177);
  }
}
.textBodySmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }
}
.textBodySmallRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
    color: rgb(105, 105, 105);
  }
}
.textBodyLargeRegularActionBaseRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(2, 118, 177);
  }
}
.textCardBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textCardBodyMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textCardBodyMediumRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(105, 105, 105);
  }
}
.textCardBodyMediumRegularPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
    color: rgb(30, 106, 11);
  }
}
.textCardBodySmallRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
    color: rgb(105, 105, 105);
  }
}
.textContactBodyLargeSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textContactBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(61, 61, 61);
  }
}
.textBannerSubheadingLargeLightBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBannerHeadingSmallSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.875rem;
    text-align: left;
    color: rgb(2, 118, 177);
  }
}

.textBannerSubheadingSmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBannerHeadingLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}
.textBannerHeadingLargeLightBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }
}

@media (min-width: 1024px) {
  .textHeadingSmallLightBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 2rem;
      font-weight: 300;
      line-height: 2.625rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textHeadingSmallSemiBoldBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 2rem;
      font-weight: normal;
      line-height: 2.625rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingLargeRegularBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.625rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textHeadingSmallSemiBoldActionBaseLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 2rem;
      font-weight: normal;
      line-height: 2.625rem;
      text-align: left;
      color: rgb(2, 118, 177);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingLargeLightSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 2.25rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingLargeRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.25rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingLargeLightSubduedLightCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 2.25rem;
      text-align: center;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingLargeRegularSubduedLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.25rem;
      text-align: left;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingMediumRegularBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingMediumLightSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 1.875rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingMediumRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.25rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingMediumRegularSubduedDarkRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: right;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingMediumLightSubduedLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: left;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingMediumLightSubduedLightRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: right;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingMediumRegularDisabledLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: left;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textDecorationLargeRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: left;
      text-decoration: underline;
      text-decoration-color: rgb(61, 61, 61);
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textDecorationLargeRegularBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
  .textBodyLargeRegularBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularBaseDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(20, 20, 20);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
  .textBodyExtraLargeRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularSubduedLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularDisabledLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeSemiBoldBaseDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(20, 20, 20);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeSemiBoldBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeSemiBoldSubduedLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeSemiBoldDisabledLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularActionBaseLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(2, 118, 177);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularActionHoverLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(2, 101, 151);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularActionActiveLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(1, 78, 116);
    }
  }
}

@media (min-width: 1024px) {
  .textDecorationMediumRegularActionHoverLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(2, 101, 151);
    }
  }
}

@media (min-width: 1024px) {
  .textDecorationMediumRegularActionActiveLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(1, 78, 116);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumRegularBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}
@media (min-width: 1024px) {
  .textBodyMediumRegularActionLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(37, 95, 130);
    }
  }
}

@media (min-width: 1024px) {
  .textDecorationSmallRegularBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumRegularBaseDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(20, 20, 20);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumRegularBaseDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: center;
      color: rgb(20, 20, 20);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
  .textBodySmallRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumSemiBoldSubduedDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.3125rem;
      text-align: center;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumSemiBoldDisabledCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.3125rem;
      text-align: center;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumRegularSubduedLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumRegularDisabledLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyMediumRegularPositiveLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(30, 106, 11);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallRegularBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallRegularSubduedLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallRegularSubduedDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.3125rem;
      text-align: center;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallRegularDisabledCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1.3125rem;
      text-align: center;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallRegularNegativeLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(204, 36, 39);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallSemiBoldSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: normal;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallSemiBoldSubduedDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: normal;
      line-height: 1.125rem;
      text-align: center;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallSemiBoldDisabledCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: normal;
      line-height: 1.125rem;
      text-align: center;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallSemiBoldActionLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(2, 118, 177);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallSemiBoldPositiveLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(30, 106, 11);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallSemiBoldWarningLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(20, 20, 20);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallSemiBoldNegativeLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(204, 36, 39);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyExtraSmallSemiBoldSubduedDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.625rem;
      font-weight: normal;
      line-height: 0.75rem;
      text-align: center;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyExtraSmallSemiBoldDisabledCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.625rem;
      font-weight: normal;
      line-height: 0.75rem;
      text-align: center;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyExtraExtraSmallRegularSubduedDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.625rem;
      font-weight: normal;
      line-height: 0.75rem;
      text-align: center;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyExtraExtraSmallRegularDisabledCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.625rem;
      font-weight: normal;
      line-height: 0.75rem;
      text-align: center;
      color: rgb(125, 125, 125);
    }
  }
}

@media (min-width: 1024px) {
  .textHeadingSmallRegularBaseLightCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.625rem;
      text-align: center;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingSmallRegularSubduedDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.875rem;
      text-align: center;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textSubheadingSmallRegularBaseLightCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.625rem;
      text-align: center;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularBaseLightCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: center;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeLightSubduedDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.5rem;
      text-align: center;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularSubduedDarkCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: center;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularActionBaseCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: center;
      color: rgb(2, 118, 177);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallRegularBaseLightCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: center;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textBodySmallRegularSubduedLightCenter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
      text-align: center;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textBodyLargeRegularActionBaseRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: right;
      color: rgb(2, 118, 177);
    }
  }
}

@media (min-width: 1024px) {
  .textCardBodyLargeRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textCardBodyMediumRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textCardBodyMediumRegularSubduedLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textCardBodyMediumRegularPositiveLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
      text-align: left;
      color: rgb(30, 106, 11);
    }
  }
}

@media (min-width: 1024px) {
  .textCardBodySmallRegularSubduedLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
      text-align: left;
      color: rgb(105, 105, 105);
    }
  }
}

@media (min-width: 1024px) {
  .textContactBodyLargeSemiBoldSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textContactBodyLargeRegularSubduedDarkLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(61, 61, 61);
    }
  }
}

@media (min-width: 1024px) {
  .textBannerSubheadingLargeRegularBaseLightLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 2rem;
      font-weight: 300;
      line-height: 2.625rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  }
}

@media (min-width: 1024px) {
  .textBannerHeadingSmallSemiBoldActionBaseLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }

    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Montserrat', 'sans-serif';
      font-size: 2rem;
      font-weight: normal;
      line-height: 2.625rem;
      text-align: left;
      color: rgb(2, 118, 177);
    }
  }
}
