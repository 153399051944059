.logoLogoC1Entertainment {
  overflow: hidden;
  align-self: flex-start;
  width: 7.625rem;
  flex: 0 0 1.25rem;
  &:nth-child(1) {
    margin-top: 0;
  }
}
.logoLogoC1Default {
  align-self: flex-start;
  width: 8.9375rem;
  flex: 0 0 3.125rem;
  &:nth-child(1) {
    margin-top: 0;
  }
}
.logoVividLogo {
  overflow: hidden;
  align-self: flex-start;
  width: 7.375rem;
  flex: 0 0 1.6875rem;
  &:nth-child(1) {
    margin-top: 0;
  }

}
.logoVividLogoWithColour {
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
}
.logoLogoTicketsOnSale {
  overflow: hidden;
  align-self: flex-start;
  width: 7.625rem;
  flex: 0 0 1.25rem;
  &:nth-child(1) {
    margin-top: 0;    
  }
}
.logoShopperApprovedSeal {
  overflow: hidden;
  align-self: flex-start;
  width: 7.375rem;
  flex: 0 0 1.6875rem;
  &:nth-child(1) {
    margin-top: 0;
  }

}