
::placeholder { 
  color: rgb(255, 255, 255);
}
.textInputEmptyClearRegular {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: rgb(255, 255, 255);
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.textInputFilledClearRegular {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: rgb(255, 255, 255);
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.textInputEmptyDefaultRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(105, 105, 105);
  border-radius: 4px;

  ::placeholder { 
    color: rgb(105, 105, 105);
  }

  .text {
    height: 1.3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultRegular:active {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  outline: 2px solid rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultRegular:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px dashed rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultRegular:error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(204, 36, 39);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultRegular:disabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(105, 105, 105);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultRegular:active {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  outline: 2px solid rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultRegular:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px dashed rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultRegular:error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(204, 36, 39);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultRegular:disabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultCenterRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(105, 105, 105);
  border-radius: 4px;

  .text {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultCenterRegular:active {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  outline: 2px solid rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultCenterRegular:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px dashed rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultCenterRegular:error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(204, 36, 39);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultCenterRegular:disabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultCenterRegular {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(105, 105, 105);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultCenterRegular:active {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  outline: 2px solid rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultCenterRegular:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px dashed rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultCenterRegular:error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(204, 36, 39);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultCenterRegular:disabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultSmall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(105, 105, 105);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultSmall:active {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  outline: 2px solid rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultSmall:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px dashed rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultSmall:error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(204, 36, 39);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputEmptyDefaultSmall:disabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultSmall {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(105, 105, 105);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultSmall:active {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  outline: 2px solid rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultSmall:focus-visible {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px dashed rgb(2, 101, 151);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultSmall:error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(204, 36, 39);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.textInputFilledDefaultSmall:disabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  > * {
    margin-top: 0rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}