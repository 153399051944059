.cardInfoBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  padding: 1rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .blockTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    p {
      font-size: 0.875rem;
    }
  }
  .methodSelection {
    align-self: stretch;
    display: flex;
    flex-direction: row;
  }

  .icons {
    border: 0.125rem solid #E3E3E8;
    border-radius: 0.25rem;
    padding: 0.5rem 0.3rem 0.3rem 0.3rem;
    min-width: 9rem;
    margin-right: 1rem;

    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .iconsSelected {
    border: 0.125rem solid rgba(0, 102, 153, 1);
    border-radius: 0.25rem;
    padding: 0.5rem 0.3rem 0.3rem 0.3rem;
    min-width: 9rem;
    margin-right: 1rem;

    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .mastercard {
    overflow: hidden;
    align-self: flex-start;
    height: 2rem;
    padding-top: 0.3rem;
    flex: 0 0 2rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .visa {
    overflow: hidden;
    align-self: flex-start;
    height: 2rem;
    padding-top: 0.3rem;
    flex: 0 0 2rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
  .paypal {
    overflow: visible;
    align-self: flex-start;
    padding: 0.3rem 0 0 2rem;
    height: 1rem;
    flex: 0 0 2rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
  .paypalMessage {
    p {
      color: #444649;
    }
  }

  .cardNumber {
    display: block;
    margin-top: 0.5rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 3rem;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    border: 1px solid #696969;
    border-radius: 4px;
  }

  .fieldGroup1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .disable {
    pointer-events: none;
    opacity: 0.4;
  }

  .expiration {
    display: block;
    margin-top: 0.5rem;
    margin-right: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 3rem;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    border: 1px solid #696969;
    border-radius: 4px;
  }

  .securityCode {
    display: block;
    margin-top: 0.5rem;
    margin-left: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 3rem;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    border: 1px solid #696969;
    border-radius: 4px;
  }

  .button {
    display: none;
  }
  .errorMessage {
    display: inline-block;
    color: #cc2427;
    font-size: 12px;
    margin-top: 0.8rem;
  }
  .errorBoundary {
    border: 2px solid #cc2427 !important;
    margin-left: 0;
  }
}
.cardInfoBlockLabels {
  margin-left: 0;
}

.cardInfoBlockLabelsSecurity {
  margin-left: 1rem;
}
.errorMessage {
  display: inline-block;
  color: #cc2427;
  font-size: 12px;
  margin-top: 0.8rem;
}
.errorBoundary {
  border: 2px solid #cc2427 !important;
  margin-left: 0;
}
@media (min-width: 1024px) {
  .cardInfoBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    > * {
      margin-top: 1rem;
    }
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .description {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      p {
        font-size: 0.875rem;
      }
    }

    .methodSelection {
      align-self: stretch;
      display: flex;
      flex-direction: row;
    }

    .icons {
      border: 0.125rem solid #E3E3E8;
      border-radius: 0.25rem;
      padding: 0.5rem 0.3rem 0.5rem 0.6rem;
      width: 10rem;
      margin-right: 1rem;

      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.3rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .iconsSelected {
      border: 0.125rem solid rgba(0, 102, 153, 1);
      border-radius: 0.25rem;
      padding: 0.5rem 0.3rem 0.5rem 0.6rem;
      width: 10rem;
      margin-right: 1rem;

      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.3rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .mastercard {
      overflow: hidden;
      align-self: flex-start;
      height: 1rem;
      padding-top: 0;
      flex: 0 0 2rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .visa {
      overflow: hidden;
      align-self: flex-start;
      height: 1rem;
      padding-top: 0;
      flex: 0 0 2rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .paypal {
      overflow: visible;
      align-self: flex-start;
      padding-left: 2rem;
      height: 1rem;
      padding-top: 0;
      flex: 0 0 2rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .cardNumber {
      display: block;
      margin-top: 0.5rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      border: 1px solid #696969;
      border-radius: 4px;
    }

    .fieldGroup1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .expiration {
      width: 16rem;
      display: block;
      margin-top: 0.5rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
      border: 1px solid #696969;
      border-radius: 4px;
    }

    .securityCode {
      width: 16rem;
      display: block;
      margin-top: 0.5rem;
      margin-left: 1rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
      border: 1px solid #696969;
      border-radius: 4px;
    }

    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5625rem 1rem 0.5625rem 1rem;
      align-self: flex-start;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .errorMessage {
      display: inline-block;
      color: #cc2427;
      font-size: 12px;
      margin-top: 0.8rem;
    }
    .errorBoundary {
      border: 2px solid #cc2427 !important;
      margin-left: 0;
    }
  }
}
