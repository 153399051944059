.dropdownDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  &:nth-child(1) {
    margin-top: 0;
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .dropdownSelect {
    height: 2.9rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    padding-right: 2rem;
    line-height: 1.25rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}

.dropdownError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.5rem;
  }
  &:nth-child(1) {
    margin-top: 0;
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .dropdownSelect {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid #CC2427;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    padding-right: 2rem;
    line-height: 1.25rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}