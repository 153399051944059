.customerInfoReadOnlyBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .blockTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .edit {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .textItem {
    // this makes so that \n can be used to break lines
    white-space: pre-line;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 1024px) {
  .customerInfoReadOnlyBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    > * {
      margin-top: 1rem;
    }
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  
    .topContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 1.875rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .blockTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .edit {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .textItem {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
  }
}