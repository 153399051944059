.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .865rem;
  word-wrap: break-word;
  opacity: 0;

  &.show { opacity: .9; }

  .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  padding: .4rem 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: .4rem (.8rem / 2) 0;
      border-top-color: #000000;
    }
  }
}

.bs-tooltip-right {
  padding: 0 .4rem;

  .arrow {
    left: 0;
    width: .4rem;
    height: .8rem;

    &::before {
      right: 0;
      border-width: (.8rem / 2) .4rem (.8rem / 2) 0;
      border-right-color: #000000;
    }
  }
}

.bs-tooltip-bottom {
  padding: .4rem 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 (.8rem / 2) .4rem;
      border-bottom-color: #000000;
    }
  }
}

.bs-tooltip-left {
  padding: 0 .4rem;

  .arrow {
    right: 0;
    width: .4rem;
    height: .8rem;

    &::before {
      left: 0;
      border-width: (.8rem / 2) 0 (.8rem / 2) .4rem;
      border-left-color: #000000;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: 12.5rem;
  padding: 1rem;
  color: #ffffff;
  text-align: left;
  background-color: #000000;
  border-radius: .25rem;
}