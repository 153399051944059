.dividerHorizontalThickWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  height: 0.1875rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

}
.dividerHorizontalThinDigitalGrey5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  height: 0.0625rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);

}
.dividerHorizontalThinDigitalGrey10 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  height: 0.0625rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(229, 229, 229);

}
.dividerHorizontalThinDigitalGrey15 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  height: 0.0625rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(212, 212, 212);

}
.dividerHorizontalThinCP1Red {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 0.0625rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(205, 34, 40);

}
.dividerHorizontalThinWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  height: 0.0625rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

}
.dividerVerticalThinWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  width: 0.0625rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

}
.dividerVerticalThinDigitalGrey15 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  width: 0.0625rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(212, 212, 212);

}
.dividerVerticalThinDigitalGrey10 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  width: 0.0625rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(229, 229, 229);

}
.dividerVerticalThickInteractionBlue50 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  width:  0.25rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 118, 177);

}
.dividerVerticalThickCP1Red {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  width:  0.25rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(204, 36, 39);

}
.dividerVerticalThickWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  width:  0.25rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

}
.dividerVerticalThickClear {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  width:  0.25rem;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

}