.shippingInfoBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1.5rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .blockTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .errorHighlight {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 229, 230);
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .icon {
    overflow: hidden;
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .checkBoxItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .fieldGroup1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .firstName {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .lastName {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .sccondaryAddress {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .fieldGroup2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .city {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .state {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .fieldGroup3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .postalCode {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .country {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .phoneNumber {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .button {
    display: none;
  }
}

@media (min-width: 1024px) {
  .shippingInfoBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    > * {
      margin-top: 1.5rem;
    }
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    .blockTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .errorHighlight {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      background-color: rgb(255, 229, 230);
    }
    .divider {
      align-self: stretch;
      flex: 0 0 auto;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .icon {
      overflow: hidden;
      align-self: flex-start;
      height: 1.5rem;
      flex: 0 0 1.5rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .message {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .checkBoxItem {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      > * {
        margin-left: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .fieldGroup1 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 1rem;
        margin-top: 0rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .firstName {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .lastName {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 0;
      > * {
        margin-top: 0.5rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .address {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 4.8125rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .sccondaryAddress {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 4.8125rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .fieldGroup2 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 1rem;
        margin-top: 0rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .city {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .state {
      max-width: 50%;
      min-width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 0;
      > * {
        margin-top: 0.5rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .fieldGroup3 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 1rem;
        margin-top: 0rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .postalCode {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .country {
      max-width: 50%;
      min-width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 0;
      > * {
        margin-top: 0.5rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    .phoneNumber {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: flex-start;
      min-width: 47.5%;
      max-width: 47.5%;
      flex: 0 0 4.8125rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5625rem 1rem 0.5625rem 1rem;
      align-self: flex-start;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}
