.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modalDialog {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 0 center;
  background-color: white;
  padding: 0rem;
  width: 50rem;
  margin: auto;
  margin-top: 3.3em;
  border-radius: 4px;
  @media (max-width: 640px) {
    width: calc(100vw - 32px);
  }
}
.modalHeader {
  display: flex;
  button {
    padding: 0.5rem;    
    margin: -1.5rem -1.2rem -0.5rem auto;
    font-size: 1.75rem;
    font-weight: 100;
  }
}
.modalContent {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-height: calc(100vh - 100px);
  padding: 32px;
  overflow-y: auto;
  color: #444649;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

.modalContent h1 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1em;
  margin-bottom: 0;
}

.modalContent h2 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 1em;
  margin-bottom: 1em;
}

.modalContent ul {
  list-style: disc outside none;
  list-style: initial;
  margin: 0;
  margin: initial;
  padding: 0 0 0 20px;
}

.modalContent p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.modalContent p,
li {
  font-size: 14px;
  line-height: 1.4em;
}

.modalContent .p1 {
  margin-bottom: 15em !important;
  font: 11.5px Helvetica;
  color: #000000;
  background-color: #ffffff;
}

.modalContent p.p2 {
  margin: 0px 0px 0px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
  background-color: #ffffff;
}

.modalContent p.p3 {
  margin: 0px 0px 0px 0px;
  font: 11.5px Helvetica;
  color: #000000;
  background-color: #ffffff;
  min-height: 14px;
}

.modalContent p.p4 {
  margin: 0px 0px 14px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
  background-color: #ffffff;
  min-height: 14px;
}

.modalContent p.p5 {
  margin: 0px 0px 14px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
  background-color: #ffffff;
}

.modalContent p.p6 {
  margin: 0px 0px 12px 0px;
  text-align: justify;
  font: 11.5px Helvetica;
  color: #3b3d44;
}

.modalContent p.p7 {
  margin: 0px 0px 1px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
}

.modalContent p.p8 {
  margin: 0px 0px 1px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
  min-height: 14px;
}

.modalContent p.p9 {
  margin: 0px 0px 1px 0px;
  font: 11.5px Helvetica;
  color: #000000;
  min-height: 14px;
}

.modalContent p.p10 {
  margin: 0px 0px 12px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
}

.modalContent p.p14 {
  margin: 0px 0px 14px 0px;
  font: 11.5px Helvetica;
  color: #313131;
  background-color: #ffffff;
}

.modalContent p.p15 {
  margin: 0px 0px 1px 0px;
  text-align: justify;
  font: 11.5px Helvetica;
  color: #3b3d44;
}

.modalContent p.p16 {
  margin: 0px 0px 1px 0px;
  text-align: justify;
  font: 11.5px Helvetica;
  color: #3b3d44;
  min-height: 14px;
}

.modalContent p.p17 {
  margin: 0px 0px 1px 36px;
  font: 11.5px Helvetica;
  color: #3b3d44;
}

.modalContent p.p18 {
  margin: 0px 0px 1px 36px;
  font: 11.5px Helvetica;
  color: #3b3d44;
  min-height: 14px;
}

.modalContent p.p19 {
  margin: 0px 0px 18px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
  background-color: #ffffff;
}

.modalContent p.p20 {
  margin: 0px 0px 18px 0px;
  font: 11.5px Helvetica;
  color: #101010;
  background-color: #ffffff;
}

.modalContent p.p21 {
  margin: 0px 0px 8px 0px;
  font: 11.5px Helvetica;
  color: #000000;
  min-height: 14px;
}

.modalContent li.li7 {
  margin: 0px 0px 1px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
}

.modalContent li.li11 {
  margin: 0px 0px 0px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
}

.modalContent li.li12 {
  margin: 0px 0px 14px 0px;
  font: 11.5px Helvetica;
  color: #3b3d44;
}

.modalContent li.li13 {
  margin: 0px 0px 14px 0px;
  font: 11.5px Helvetica;
  color: #000000;
}

.modalContent span.s1 {
  color: #3b3d44;
}

.modalContent span.s2 {
  color: #000000;
}

.modalContent span.s3 {
  color: #545555;
  background-color: #ffffff;
}

.modalContent span.s4 {
  font: 10px Helvetica;
}

.modalContent span.s5 {
  background-color: #ffffff;
}

.modalContent span.s6 {
  color: #000000;
  background-color: #ffffff;
}

.modalContent span.s7 {
  text-decoration: underline;
  color: #0000ff;
}

.modalContent span.s8 {
  color: #474747;
}

.modalContent span.s9 {
  color: #333333;
}

.modalContent span.s10 {
  color: #545555;
}

.modalContent ul.ul1 {
  list-style-type: disc;
}

.modal > .close-button {
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
}
