.orderInfo {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1.5rem;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 4px;
  .titleContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(255, 255, 255);
  }
  .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: flex-start;
    flex: 0 0 auto;
  }
  .amount {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-end;
    flex: 0 0 auto;
  }
  .miles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-end;
    flex: 0 0 auto;
  }
  .costBreakdown {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.5rem 1rem 0.5rem;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .eventInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 1.3125rem;
  }
  .venue {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 1.3125rem;
  }
  .seatInfo {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 1.5rem;
    }
    align-self: stretch;
    flex: 0 0 2.875rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .sellerNotes {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .deliveryMethod {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .logo {
    overflow: hidden;
    align-self: flex-start;
    width: 6.625rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .faceValue {
    padding-top: 0.3rem;
    padding-bottom: 0.2rem;
    border-top: 0.05rem solid dimgray;
    border-bottom: 0.05rem solid dimgray;
  }
}
@media (min-width: 1024px) {
  .orderInfo {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1rem 1.5rem 1rem;
    > * {
      margin-top: 1.5rem;
    }
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(212, 212, 212);
    border-radius: 4px;
    .titleContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      background-color: rgb(255, 255, 255);
    }
    .title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .price {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      align-self: flex-start;
      flex: 0 0 auto;
    }
    .amount {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;
      flex: 0 0 auto;
    }
    .miles {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-end;
      flex: 0 0 auto;
    }
    .costBreakdown {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0.5rem 1rem 0.5rem;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .eventInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .dateAndTime {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 1.3125rem;
    }
    .venue {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 1.3125rem;
    }
    .seatInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      > * {
        margin-left: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 2.875rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .sellerNotes {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .deliveryMethod {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .logo {
      overflow: hidden;
      align-self: flex-start;
      width: 6.625rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}