.customerReviewContainer {
    border: 1px solid #d4d4d4;
    background: #fff;
    border-radius: 0.25rem;
    padding: 0 1.25rem 1.25rem;
    float: left;
    width: 100%;

    .starItemContainer {
        display: flex;
        flex-direction: column;
        padding-left: 0.1rem;
        li {
            line-height: 1.5rem;
        }
    }

    .starItem {
        display: flex;
    }

    .reviewDescription {
        font-family: 'Montserrat', 'sans-serif';
        color: #4B676E;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.125rem;
        font-style: italic;
        margin: 0;
    }
    .reviewerName{
        font-family: 'Montserrat', 'sans-serif';
        color: #4B676E;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 1rem;
        margin-top: 0.3rem;
        margin-bottom: 0.8rem;
    }

}
.blockTitle {
    color: #006498;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.125rem;
    border-bottom: 0.063rem solid #eee;
    padding: 0 0 0.375rem;
    margin: 0.938rem 0;
    float: left;
    width: 100%;
}