.tagNegativeSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 229, 230);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.tagNegativeRegular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 229, 230);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.tagInformationSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(225, 245, 255);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.tagInformationRegular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(225, 245, 255);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.tagNeutralSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(244, 244, 244);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.tagPositiveSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(218, 232, 216);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.tagPositiveRegular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(218, 232, 216);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.tagWarningSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 241, 208);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.tagWarningRegular {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
  > * {
    margin-top: 0.625rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 241, 208);
  border-radius: 100px;

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}