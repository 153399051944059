.orderErrorContainer {
  width: 100%;
  height: 100%;
}

.checkoutManualPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .stepperBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .billingInfoButton {
    margin-top: 1.5rem !important;
  }
  .phoneNumberPrivacyErrorCheckbox {
    border-width: 0.063rem;
    border-color: rgb(204, 36, 39);
    border-style: solid;
    width: 1.6rem;
  }
  .phoneNumberPrivacyErrorMessage {
    display: none;
  }
  .orderInfoMobile {
    align-self: stretch;
    margin: 0.625rem 1rem 0 1rem;
  }
  .checkoutFlowBlock {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.625rem 1rem 0 1rem;
    padding: 1rem 1rem 1.5rem 1rem;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    p, span {
      font-family: 'Montserrat';
    }
  }

  .cardInfoBlockLabelsSecurity {
    margin-left: 1rem;
  }

  .mobileRightContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0.625rem 1rem 0 1rem;
    align-items: flex-start;
    > * {
      margin-top: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5625rem 1rem 0.5625rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .rightContent {
    display: none;
  }

  .ticketInfo {
    display: none;
  }
}

@media (min-width: 1024px) {
  .checkoutManualPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgb(244, 244, 244);

    .container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 1.5rem 3rem 1.5rem 3rem;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .stepperBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .orderInfoMobile {
      display: none;
    }
    .checkoutFlowBlock {
      background-color: white;
      border-radius: 4px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

      width: 39rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0;
      padding: 1.5rem 2.5rem 1.5rem 2.5rem;
      > * {
        margin-top: 1rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      p, span {
        font-family: 'Montserrat';
      }
    }

    .leftContent {
      order: 1;
      display: flex;
      padding: 0rem 1rem 0.25rem 0.25rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      > * {
        margin-top: 1rem;
      }
      align-self: flex-start;
      flex: 0 0 37.5rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .rightContent {
      order: 2;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      > * {
        margin-top: 0.625rem;
      }
      align-self: flex-start;
      flex: 0 0 20rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .ticketInfo {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 20rem;
      padding: 1.5rem 1rem 1.5rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .mobileLeftContent {
      display: none;
    }

    .mobileRightContent {
      display: none;
    }

    .button {
      display: none;
    }
  }
}
