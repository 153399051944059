.costBreakdown {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 0.5rem 1rem 0.5rem;
  > * {
    margin-top: 0.5rem;
  }
  background-color: rgb(244, 244, 244);

  .ordarTotal {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .tickets {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .serviceFee {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .delivery {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  .tax {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .promoCodeContainer {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0rem 0.5rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .promoInputContainer {
    display: flex;
    flex-direction: row;
    outline: 0.06rem solid dimgray;
    width: 100%;
  }
  .promoInputContainer:active {
    outline: 0.15rem solid rgb(0, 102, 153);
  }
  .promoInputError {
    display: flex;
    flex-direction: row;
    outline: 0.15rem solid  #CC2427;
    width: 100%;
  }
  .promoInput {
    height: 2rem;
    padding: 0.3rem 1rem 0.75rem 1rem;
    border-radius: 0;
    width: 70%;
  }
  .promoInput:active {
    height: 2rem;
    padding: 0.3rem 1rem 0.75rem 1rem;
    border: 1px solid dimgray;
    outline: unset;
    border-radius: 0;
    width: 70%;
  }

  .applyButton {
    padding-left: 0.5rem;
    background-color: rgb(0, 102, 153);
    width: 100%;
    p {
      color: white;
      text-align: center;
    }
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }
}
