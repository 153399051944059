.iconTextDescriptionDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .icon {
    overflow: hidden;
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    p {
      font-size: 0.875rem;
    }
  }

}
.iconTextDescriptionWithLogo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .icon {
    overflow: hidden;
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    p {
      font-size: 0.875rem;
    }
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 6.25rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .logo {
    align-self: flex-start;
    flex: 1 1 auto;
    max-width: 4rem;
  }

}