.myTicketsPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .myTicketsBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-top: 1.5rem;
    }
    align-self: flex-start;
    flex: 1 1 auto;
  }
}

.modal {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
  padding: 0rem;
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  @media (min-width: 1024px) {
    margin-top: 2.5rem;
    padding-top: 2.5rem;
  }
  > * {
    padding: 0rem 2rem 0rem 2rem !important;
  }
}
.orderDetailsContainer {
  height: 70% !important;
}

.dateCreated {
  margin-left: 0 !important;
}
.hideIcons {
  display: none;
}
@media (min-width: 1024px) {
  .modalDialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    height: 100%;
    padding: 8.5rem 1.5rem 8.5rem 1.5rem;
  }

  .myTicketsPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 60rem;
      flex: 0 0 28rem;
    }

    .myTicketsBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 0rem 1.5rem 0rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  }
}
