.footerMenuList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-top: 1.5rem;
    margin-left: 1.5rem;
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(-n+9) {
      margin-top: 0;
    }
    &:nth-child(9n+1) {
      margin-left: 0;
    }
  }

}