.iconCloseDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(24%) sepia(0%) saturate(1123%) hue-rotate(147deg) brightness(90%) contrast(93%);
  }

}
.iconCloseActionBase {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(30%) sepia(21%) saturate(7128%) hue-rotate(180deg) brightness(91%) contrast(98%);
  }

}
.iconCloseWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(333%) hue-rotate(274deg) brightness(113%) contrast(100%);
  }

}
.iconCloseCircleDigitalGrey100 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(22%) sepia(9%) saturate(12%) hue-rotate(315deg) brightness(93%) contrast(89%);
  }

}

.iconCloseCircleActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(98%) saturate(3247%) hue-rotate(186deg) brightness(91%) contrast(100%);
  }
}

.iconCloseCircleWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(7deg) brightness(107%) contrast(100%);
  }

}
.iconCloseCircleFillDigitalGrey100 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(8%) saturate(1570%) hue-rotate(14deg) brightness(96%) contrast(84%);
  }

}
.iconCheckboxEmptyDigitalGrey100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(105, 105, 105);
  border-radius: 2px;

}
.iconCheckboxFilledDigitalGrey100 {
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(2, 117, 177);
  border-radius: 2px;

  .content {
    width: 100%;
    height: 100%;

  }

}
.iconChevronUpDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(26%) sepia(0%) saturate(38%) hue-rotate(147deg) brightness(85%) contrast(94%);
  }

}
.iconChevronUpWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(201deg) brightness(106%) contrast(106%);
  }

}
.iconChevronUpActionHover {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(16%) sepia(93%) saturate(3328%) hue-rotate(186deg) brightness(99%) contrast(98%);
  }

}
.iconChevronUpActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(98%) saturate(3247%) hue-rotate(186deg) brightness(91%) contrast(100%);
  }

}
.iconChevronUpFilledDigitalGrey100 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(3%) sepia(9%) saturate(4%) hue-rotate(314deg) brightness(103%) contrast(90%);
  }

}
.iconChevronDownDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(1%) saturate(6821%) hue-rotate(38deg) brightness(118%) contrast(94%);
  }

}
.iconChevronDownWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(4391%) hue-rotate(210deg) brightness(117%) contrast(100%);
  }

}
.iconChevronDownActionHover {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(20%) sepia(87%) saturate(1865%) hue-rotate(179deg) brightness(105%) contrast(98%);
  }

}
.iconChevronDownActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(88%) saturate(4570%) hue-rotate(187deg) brightness(90%) contrast(99%);
  }

}
.iconChevronDownFilledDigitalGrey100 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(5%) sepia(1%) saturate(8%) hue-rotate(341deg) brightness(95%) contrast(93%);
  }

}
.iconChevronLeftDigitalGrey100 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(2716%) hue-rotate(206deg) brightness(83%) contrast(84%);
  }

}
.iconChevronLeftWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(94%) sepia(100%) saturate(0%) hue-rotate(243deg) brightness(107%) contrast(106%);
  }

}
.iconChevronLeftActionBase {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(25%) sepia(56%) saturate(2806%) hue-rotate(180deg) brightness(102%) contrast(98%);
  }

}
.iconChevronLeftActionHover {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(20%) sepia(51%) saturate(4023%) hue-rotate(183deg) brightness(100%) contrast(98%);
  }

}
.iconChevronLeftActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(99%) saturate(3858%) hue-rotate(187deg) brightness(77%) contrast(99%);
  }

}
.iconChevronLeftFilledDigitalGrey100 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(6%) sepia(2%) saturate(669%) hue-rotate(315deg) brightness(104%) contrast(97%);
  }

}
.iconChevronRightDigitalGrey100 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(4%) saturate(5939%) hue-rotate(321deg) brightness(87%) contrast(84%);
  }

}
.iconChevronRightActionBase {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(39%) sepia(56%) saturate(7500%) hue-rotate(181deg) brightness(89%) contrast(102%);
  }

}
.iconChevronRightActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(28%) sepia(87%) saturate(774%) hue-rotate(164deg) brightness(97%) contrast(104%);
  }

}
.iconChevronRightWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(97%) sepia(100%) saturate(14%) hue-rotate(202deg) brightness(103%) contrast(103%);
  }

}
.iconChevronRightFilledDigitalGrey100 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(5%) saturate(2762%) hue-rotate(90deg) brightness(126%) contrast(84%);
  }

}
.iconSearchSmallWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(314deg) brightness(105%) contrast(100%);
  }

}
.iconSearchWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(52%) saturate(0%) hue-rotate(359deg) brightness(109%) contrast(101%);
  }

}
.iconLocationMarkerActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(18%) sepia(94%) saturate(2690%) hue-rotate(184deg) brightness(94%) contrast(98%);
  }

}
.iconLocationMarkerSmallWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(96%) sepia(3%) saturate(74%) hue-rotate(94deg) brightness(120%) contrast(100%);
  }

}
.iconCalendarActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(46%) saturate(6501%) hue-rotate(186deg) brightness(88%) contrast(98%);
  }

}
.iconVSActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(98%) saturate(2265%) hue-rotate(183deg) brightness(96%) contrast(98%);
  }

}
.iconCalendarLargeDigitalGrey30 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(69%) sepia(0%) saturate(157%) hue-rotate(193deg) brightness(98%) contrast(90%);
  }

}
.iconProfileDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(31%) hue-rotate(234deg) brightness(105%) contrast(95%);
  }

}
.iconDeliveryDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(0%) hue-rotate(359deg) brightness(96%) contrast(88%);
  }

}
.iconCheckmarkInCircleDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(22%) sepia(7%) saturate(0%) hue-rotate(142deg) brightness(99%) contrast(94%);
  }

}
.iconTicketBigDigitalGrey30 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(74%) sepia(4%) saturate(13%) hue-rotate(338deg) brightness(92%) contrast(83%);
  }

}
.iconFootballDigitalGrey30 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(81%) sepia(0%) saturate(0%) hue-rotate(167deg) brightness(84%) contrast(86%);
  }

}
.iconTicketDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(24%) sepia(15%) saturate(0%) hue-rotate(248deg) brightness(88%) contrast(93%);
  }

}
.iconTicketWithLinesDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(25%) sepia(8%) saturate(277%) hue-rotate(177deg) brightness(94%) contrast(87%);
  }

}
.iconClockWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(283deg) brightness(109%) contrast(101%);
  }

}
.iconClockLargeDigitalGrey60 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(41%) sepia(4%) saturate(24%) hue-rotate(42deg) brightness(97%) contrast(91%);
  }

}
.iconShirtWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(0%) hue-rotate(246deg) brightness(106%) contrast(105%);
  }

}
.iconArenaWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(1470%) hue-rotate(191deg) brightness(116%) contrast(100%);
  }

}
.iconCheckmarkActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(21%) sepia(56%) saturate(1604%) hue-rotate(171deg) brightness(95%) contrast(99%);
  }

}
.iconCheckmarkDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(20%) sepia(0%) saturate(3233%) hue-rotate(317deg) brightness(91%) contrast(80%);
  }

}
.iconSettingsDigitalGrey60 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(42%) sepia(11%) saturate(14%) hue-rotate(341deg) brightness(92%) contrast(85%);
  }

}
.iconThumbtackDigitalGrey30 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .e {
    filter: brightness(0) saturate(100%) invert(81%) sepia(1%) saturate(1818%) hue-rotate(193deg) brightness(96%) contrast(87%);
  }

}
.iconMinusDigitalGrey60 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .a {
    background-color: rgb(108, 114, 117);
  }

}
.iconPlusDigitalGrey60 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(2340%) hue-rotate(345deg) brightness(108%) contrast(92%);
  }

}
.iconPlusActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(35%) sepia(13%) saturate(2354%) hue-rotate(159deg) brightness(86%) contrast(87%);
  }

}
.iconPlusActionBase {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(21%) sepia(62%) saturate(5664%) hue-rotate(187deg) brightness(98%) contrast(98%);
  }

}
.iconMailDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(0%) saturate(1%) hue-rotate(250deg) brightness(100%) contrast(85%);
  }

}
.iconPrinterActionBase {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(24%) saturate(2674%) hue-rotate(165deg) brightness(88%) contrast(101%);
  }

}
.iconPrinterActionActive {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(18%) sepia(66%) saturate(2059%) hue-rotate(177deg) brightness(100%) contrast(99%);
  }

}
.iconTicketCutDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(19%) sepia(14%) saturate(0%) hue-rotate(140deg) brightness(98%) contrast(87%);
  }

}
.iconMobileScanDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(22%) sepia(7%) saturate(26%) hue-rotate(341deg) brightness(95%) contrast(92%);
  }

}
.iconInfoDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(5%) saturate(5132%) hue-rotate(357deg) brightness(99%) contrast(84%);
  }

}
.iconInfoWhite {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(200deg) brightness(104%) contrast(103%);
  }

}
.iconWarningDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(0%) sepia(15%) saturate(5000%) hue-rotate(334deg) brightness(100%) contrast(84%);
  }

}
.iconWarningOutlineDigitalGrey60 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(18%) sepia(7%) saturate(140%) hue-rotate(314deg) brightness(106%) contrast(84%);
  }

}
.iconMasterCardDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(53%) sepia(76%) saturate(1361%) hue-rotate(201deg) brightness(89%) contrast(88%);
    border: 0px solid rgb(151, 151, 151);
    border-radius: 3.200000047683716px;
  }

  .content2 {
    width: 100%;
    height: 100%;
    background-color: rgb(246, 99, 95);
    border: 0px solid rgb(151, 151, 151);
  }

  .content3 {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 181, 72);
    border: 0px solid rgb(151, 151, 151);
  }

  .content4 {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 129, 80);
    border: 0px solid rgb(151, 151, 151);
  }

}
.iconVisaDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(104deg) brightness(116%) contrast(118%);
    border: 0px solid rgb(220, 220, 220);
    border-radius: 3.200000047683716px;
  }

  .content2 {
    width: 100%;
    height: 100%;
    background-color: rgb(86, 92, 192);
    border: 0px solid rgb(150, 150, 150);
  }

  .content3 {
    width: 100%;
    height: 100%;
    background-color: rgb(235, 169, 61);
    border: 0px solid rgb(150, 150, 150);
  }

  .content4 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(8%) saturate(3530%) hue-rotate(192deg) brightness(96%) contrast(86%);
  }

}
.iconAmericanExpressDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(104deg) brightness(116%) contrast(118%);
    border: 0px solid rgb(220, 220, 220);
    border-radius: 3.200000047683716px;
  }

  .content2 {
    width: 100%;
    height: 100%;
    background-color: rgb(86, 92, 192);
    border: 0px solid rgb(150, 150, 150);
  }

  .content3 {
    width: 100%;
    height: 100%;
    background-color: rgb(235, 169, 61);
    border: 0px solid rgb(150, 150, 150);
  }

  .content4 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(8%) saturate(3530%) hue-rotate(192deg) brightness(96%) contrast(86%);
  }

}
.iconDiscoverDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(104deg) brightness(116%) contrast(118%);
    border: 0px solid rgb(220, 220, 220);
    border-radius: 3.200000047683716px;
  }

  .content2 {
    width: 100%;
    height: 100%;
    background-color: rgb(86, 92, 192);
    border: 0px solid rgb(150, 150, 150);
  }

  .content3 {
    width: 100%;
    height: 100%;
    background-color: rgb(235, 169, 61);
    border: 0px solid rgb(150, 150, 150);
  }

  .content4 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(8%) saturate(3530%) hue-rotate(192deg) brightness(96%) contrast(86%);
  }

}
.iconPhoneDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.3rem;
  height: 2rem;
  padding-top: 0.7rem;
  flex: 0 0 1.3rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(104deg) brightness(116%) contrast(118%);
    border: 0px solid rgb(220, 220, 220);
    border-radius: 3.200000047683716px;
  }

  .content2 {
    width: 100%;
    height: 100%;
    background-color: rgb(86, 92, 192);
    border: 0px solid rgb(150, 150, 150);
  }

  .content3 {
    width: 100%;
    height: 100%;
    background-color: rgb(235, 169, 61);
    border: 0px solid rgb(150, 150, 150);
  }

  .content4 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(8%) saturate(3530%) hue-rotate(192deg) brightness(96%) contrast(86%);
  }

}
.iconPaypalDigitalGrey80 {
  overflow: hidden;
  align-self: flex-start;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content1 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(104deg) brightness(116%) contrast(118%);
    border: 0px solid rgb(220, 220, 220);
    border-radius: 3.200000047683716px;
  }

  .content2 {
    width: 100%;
    height: 100%;
    background-color: rgb(86, 92, 192);
    border: 0px solid rgb(150, 150, 150);
  }

  .content3 {
    width: 100%;
    height: 100%;
    background-color: rgb(235, 169, 61);
    border: 0px solid rgb(150, 150, 150);
  }

  .content4 {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(8%) saturate(3530%) hue-rotate(192deg) brightness(96%) contrast(86%);
  }

}