.checkBoxItemUnselected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 1rem;
  }
  align-self: start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .icon {
    border: none;
    align-self: center;
    height: 1.6rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}
.checkBoxItemUnselected:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 1rem;
  }
  align-self: start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .icon {
    align-self: center;
    height: 1.6rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}
.checkBoxItemUnselected:focus-visible {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 1rem;
  }
  align-self: start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border: 1px dashed rgb(20, 20, 20);

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}
.checkBoxItemSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 1rem;
  }
  align-self: start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}
.checkBoxItemSelected:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 1rem;
  }
  align-self: start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}
.checkBoxItemSelected:focus-visible {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 1rem;
  }
  align-self: start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  border: 1px dashed rgb(0, 0, 0);

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}
