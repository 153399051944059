.legalBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .blockTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .legalTerms {
    display: flex;
    flex-direction: row;
  }

  .description {
    // this makes so that \n can be used to break lines
    white-space: pre-line;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    img {
      filter: brightness(0) saturate(100%) invert(19%) sepia(98%) saturate(2265%) hue-rotate(183deg) brightness(96%) contrast(98%);
      height: 1rem;
    }
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .button {
    display: none;
  }
  .button:disabled {
    display: none;
  }

}

@media (min-width: 1024px) {
  .legalBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    > * {
      margin-top: 1rem;
    }
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .blockTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .legalTerms {
      display: flex;
      flex-direction: row;
    }
  
    .description {
      // this makes so that \n can be used to break lines
      white-space: pre-line;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      img {
        filter: brightness(0) saturate(100%) invert(19%) sepia(98%) saturate(2265%) hue-rotate(183deg) brightness(96%) contrast(98%);
        height: 1rem;
      }
    }
  
    .button, .button:disabled {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5625rem 1rem 0.5625rem 1rem;
      align-self: flex-start;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
  }
}