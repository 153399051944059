.stepperItemSelectedIncomplete {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start; 
  flex: 0 0 3.0625rem;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .indicator {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 0.1875rem;
    background-color: rgb(16, 37, 63);
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .stepNumber {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    p {
      color: #3D3D3D
    }
  }

  .stepLabel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    p {
      color: #3D3D3D
    }
    @media (max-width: 430px) {
      p {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
  }
  
}
.stepperItemUnselectedIncomplete {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  flex: 0 0 3.0625rem;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .indicator {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 0.1875rem;
    background-color: rgb(212, 212, 212);
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .stepNumber {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    p {
      color: #7D7D7D
    }
  }

  .stepLabel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    p {
      color: #7D7D7D
    }
    @media (max-width: 430px) {
      p {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
  }

}
.stepperItemUnselectedComplete {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  flex: 0 0 3.0625rem;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .indicator {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 0.1875rem;
    background-color: rgb(125, 125, 125);
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .stepNumber {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    p {
      color: #7D7D7D
    }
  }

  .stepLabel {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    p {
      color: #7D7D7D
    }
    @media (max-width: 430px) {
      p {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
  }

}

@media (min-width: 1024px) {
  .stepperItemSelectedIncomplete {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 3.5625rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .indicator {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 0.5rem;
      background-color: rgb(1, 61, 88);
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .stepNumber {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .stepLabel {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .stepperItemUnselectedIncomplete {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 3.5625rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .indicator {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 0.5rem;
      background-color: rgb(212, 212, 212);
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .stepNumber {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .stepLabel {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .stepperItemUnselectedComplete {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 3.5625rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  
    .indicator {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 0.5rem;
      background-color: rgb(125, 125, 125);
    }
  
    .content {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .stepNumber {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .stepLabel {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.625rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}