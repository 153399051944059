.loyaltyBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .blockTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    p {
      font-size: 0.875rem;
    }
  }

  .sliderInteraction {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-top: 1.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .highlightMessage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

@media (min-width: 1024px) {
  .loyaltyBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    > * {
      margin-top: 2rem;
    }
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .description {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      p {
        font-size: 0.875rem;
      }
    }

    .sliderInteraction {
      margin-left: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .highlightMessage {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}
